import styles from '@/styles/Home/LoggedMobile.module.scss';
import { useRouter } from 'next/router';
import { useState } from 'react';
import CurveBackground from '../SVG/CurveBackground';
import MobileGrphIcon from '../SVG/MobileGraphIcon';
import MobileHomeIcon from '../SVG/MobileHomeIcon';
import MobilePlusIcon from '../SVG/MobilePlusIcon';
import MobileSearchIcon from '../SVG/MobileSearchIcon';
import MobileUserIcon from '../SVG/MobileUserIcon';
export default function FixedBottomNavigation() {
  const [value, setValue] = useState(0);
  const router = useRouter();

  return (
    // <Paper
    //   sx={{
    //     position: 'fixed',
    //     bottom: 16,
    //     left: 16,
    //     right: 16,
    //     zIndex: '99999',
    //   }}
    //   elevation={3}
    // >
    <div className={styles.mainContainer}>
      <div className={styles.mainContainer_navigation}>
        <div className={styles.mainContainer_navigation_overlay}>
          <CurveBackground />
        </div>
        <button
          className={styles.mainContainer_navigation_button}
          style={{
            backgroundColor: router.pathname === '/' ? '#f2f2f2' : '',
          }}
          onClick={() => router.push('/')}
        >
          <MobileHomeIcon
            color={router.pathname === '/' ? '#d12d34' : '#ffffff'}
          />
          <span>Home</span>
        </button>
        <button
          className={styles.mainContainer_navigation_button}
          onClick={() => router.push('/search/m')}
          style={{
            backgroundColor: router.pathname.includes('/search')
              ? '#f2f2f2'
              : '',
          }}
        >
          <MobileSearchIcon
            color={router.pathname.includes('/search') ? '#d12d34' : '#ffffff'}
          />
          <span>Search</span>
        </button>
        <button
          className={styles.mainContainer_navigation_pbutton}
          onClick={() => router.push('/post')}
          style={{
            backgroundColor: router.pathname.includes('/post') ? '#f2f2f2' : '',
          }}
        >
          <MobilePlusIcon
            color={router.pathname.includes('/post') ? '#d12d34' : '#ffffff'}
          />
          <span>Post</span>
        </button>
        <button
          className={styles.mainContainer_navigation_button}
          onClick={() => router.push('/dubai/property-market-overview')}
        >
          <MobileGrphIcon />
          <span>Market Insights</span>
        </button>
        <button
          className={styles.mainContainer_navigation_button}
          onClick={() => router.push('/auth/profile')}
        >
          <MobileUserIcon />
          <span>Profile</span>
        </button>
      </div>
      {/* <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        style={{}}
      >
        <BottomNavigationAction
          onClick={() => router.push('/')}
          label="Home"
          icon={<HomeIcon />}
          style={{ fontSize: '0.75rem' }}
        />
        <BottomNavigationAction
          onClick={() => router.push('/post')}
          label="Post"
          icon={<AddIcon />}
          style={{ fontSize: '0.75rem' }}
        />
        <BottomNavigationAction
          onClick={() => router.push('/search/deals/hot-deals')}
          label="Hot Deals"
          icon={
            <img
              width={30}
              height={30}
              src="/assets/svg/hotdeal.svg"
              alt="hot"
            />
          }
          style={{ fontSize: '0.5rem !important' }}
        />

        <BottomNavigationAction
          onClick={() => router.push('/search/deals/hot-buyers')}
          label="Hot Buyers"
          icon={
            <img
              width={30}
              height={30}
              src="/assets/svg/investor.svg"
              alt="hot"
            />
          }
          style={{ fontSize: '0.75rem' }}
        />
      </BottomNavigation> */}
    </div>
  );
}

interface MessageExample {
  primary: string;
  secondary: string;
  person: string;
}
