import { useEffect, useRef, useState } from 'react';

import api from '@/services/api';
import styles from '@/styles/Profile/location.module.scss';
import { isMobile } from 'react-device-detect';
import SwiperCore from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

import Arrowback from '@/Components/Buttons/ArrowBack';
import ArrowForward from '@/Components/Buttons/ArrowForward';
import HomeAgentMainSaleCard from '@/Components/Cards/HomeAgentMainSaleCard';
import { useSession } from 'next-auth/react';

const AreaSpecialistRequirementLocation = ({ notlog }: any) => {
  const user = useSession().data;

  const [value, setvalue] = useState('');
  const [count, setCount] = useState<number | null>();
  //@ts-ignore
  const handleChange = (event, newValue) => {
    setvalue(newValue);
  };

  const [locatinlistings, setlocatinlistings] = useState<any[] | null>();
  const fetchDatas = async () => {
    const resp = await api.get(`/agent/agent-listings-exclusive`);
    console.log(resp.data, 'top');
    setlocatinlistings(resp.data);
  };
  useEffect(() => {
    fetchDatas();
  }, []);

  const swiperRef = useRef<SwiperCore>(null);
  const [isBeginning, setIsBeginning] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const handleSlideChange = () => {
    const swiper = swiperRef.current;
    if (swiper) {
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }
  };
  return (
    <div className={styles.TopLocations}>
      <div
        className={styles.TopLocations_itemss}
        style={{
          position: 'relative',
          paddingBottom: isMobile ? '10px' : '60px',
        }}
      >
        {!isMobile && (
          <Arrowback
            landing={true}
            onClick={() => swiperRef.current?.slidePrev()}
            state={isBeginning ? 'disabled' : 'active'}
          />
        )}

        {!isMobile && (
          <ArrowForward
            landing={true}
            onClick={() => swiperRef.current?.slideNext()}
            state={isEnd ? 'disabled' : 'active'}
          />
        )}
        {locatinlistings && (
          <Swiper
            breakpoints={{
              360: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 1,
              },
              375: {
                slidesPerView: 1.1,
                spaceBetween: 10,
              },
              410: {
                slidesPerView: 1.2,
                spaceBetween: 10,
              },
              420: {
                slidesPerView: 1.2,
                spaceBetween: 10,
              },
              680: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 2.65,
                spaceBetween: 20,
              },
            }}
            slidesPerView="auto"
            className="swiperr-bot"
            speed={800}
            onBeforeInit={(swiper) => {
              //@ts-ignore
              swiperRef.current = swiper;
            }}
            onSlideChange={handleSlideChange}
          >
            {locatinlistings.map(
              (listings: any) =>
                listings && (
                  <SwiperSlide key={listings._id}>
                    <HomeAgentMainSaleCard time={false} cardDatas={listings} />
                  </SwiperSlide>
                )
            )}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default AreaSpecialistRequirementLocation;
