import React from 'react';

interface IconProps {
  color?: string;
  width?: number;
  height?: number;
}

const MobileUserIcon: React.FC<IconProps> = ({
  color = 'white',
  width = 26,
  height = 26,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6673 22.75V20.5833C21.6673 19.4341 21.2108 18.3319 20.3981 17.5192C19.5855 16.7065 18.4833 16.25 17.334 16.25H8.66732C7.51805 16.25 6.41585 16.7065 5.60319 17.5192C4.79053 18.3319 4.33398 19.4341 4.33398 20.5833V22.75"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9993 11.9167C15.3926 11.9167 17.3327 9.97657 17.3327 7.58333C17.3327 5.1901 15.3926 3.25 12.9993 3.25C10.6061 3.25 8.66602 5.1901 8.66602 7.58333C8.66602 9.97657 10.6061 11.9167 12.9993 11.9167Z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MobileUserIcon;
