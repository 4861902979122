import DetectMobile from '@/utils/DetectMobile';

const SlideLeftSvg = ({
  color = '#F0F1F4',
  width = DetectMobile() ? 32 : 40,
  height = DetectMobile() ? 32 : 40,
  disabled = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="type=left, state=Default" opacity={disabled ? '0.6' : '1'}>
        <rect width="40" height="40" rx="20" fill={color} />
        <g id="icon/arrow">
          <path
            id="Vector"
            d="M22.0039 25.0005L18.3294 20.8843C17.8954 20.3982 17.8954 19.6028 18.3294 19.1167L22.0039 15.0005"
            stroke="#363352"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default SlideLeftSvg;
