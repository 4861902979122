import React from 'react';

interface SvgProps {
  width?: number;
  height?: number;
}

const CurveBackground: React.FC<SvgProps> = ({ width = 390, height = 60 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 390 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 30C0 13.4314 13.4315 0 30 0H150.313C159 0 167.254 3.79621 172.908 10.3923L174.609 12.3766C186.109 25.7935 207.069 25.1324 217.701 11.0176C222.927 4.07997 231.108 0 239.794 0H360C376.569 0 390 13.4314 390 30C390 46.5685 376.569 60 360 60H30C13.4315 60 0 46.5685 0 30Z"
        fill="#D12D34"
      />
    </svg>
  );
};

export default CurveBackground;
