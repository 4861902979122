// utils/useAutoSessionExpireDetection.tsx
import DialogModals from '@/Components/Modals/DialogModals';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import { ModalContext } from '../../utils/ModalContext';

function useAutoSessionExpireDetection() {
  const { data: session, status } = useSession();
  const { openModal } = useContext(ModalContext);
  const router = useRouter();
  useEffect(() => {
    // Check for session expiration here

    const interval = setInterval(() => {
      if (status === 'unauthenticated') {
        openModal(
          <DialogModals
            action={() => {
              router.push('/login');
            }}
            title="Session Expired!"
            type="warning"
            message={`Please Login again.`}
          />
        );

        clearInterval(interval);
      }
    }, 1000); // Check every second (adjust as needed)

    return () => {
      clearInterval(interval);
    };
  }, [session]);
}

export default useAutoSessionExpireDetection;
