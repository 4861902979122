import api from '@/services/api';
import styles from '@/styles/Home/Landing.module.scss';
import { ModalContext } from '@/utils/ModalContext';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';
import DialogModals from '../Modals/DialogModals';
import AreaSpecialistRequirementLocation from '../Pages/Profile/AreaSpecialistRequirementLocation';
import agentsOval from './Landing/agents-oval.png';
import alntMobileDemo from './Landing/alnt-mobile-demo.png';
import appStoreButton from './Landing/app-store-button.png';
import curveBg from './Landing/curve-bg.png';
import googlePlayButton from './Landing/google-play-button.png';
import headFrame from './Landing/head_frame.svg';
import joinElite from './Landing/join-elite.png';
import mobileDownloads from './Landing/mobile-downloads.png';
import mobileHoldLanding from './Landing/mobile-hold-landing.png';
import realTimeMobile from './Landing/real-time-mobile.png';
import mobileTailored from './Landing/taylored.png';
import verifiedMobile from './Landing/verified-mobile.png';
import worldMap from './Landing/world-map.svg';

const LandingPage = () => {
  const router = useRouter();
  const [email, setemail] = useState('');
  const handleSubscribe = async () => {
    await api.post(`/newsletter/save-email-newsletter`, {
      email: email,
    });
    Swal.fire(
      'Subscribed!',
      "We're excited to welcome and share updates and exclusive content with you. Keep an eye on your inbox for our invitation.",
      'success'
    );
  };
  const { openModal } = useContext(ModalContext);
  const handleButtonClick = () => {
    openModal(
      <DialogModals
        action={async () => {}}
        title="Info!"
        type="info"
        label="Ok"
        message={`App Launching December 2024!

In the meantime, you can use the web version of the app directly in your mobile browser for a seamless experience.`}
      />
    );
  };
  return (
    <div className={styles.mainContainer}>
      <div style={{ height: isMobile ? '250px' : '636px' }}>
        <div className={styles.mainContainer_head}>
          <Image
            priority
            className={styles.mainContainer_head_oimg}
            src={headFrame}
            alt="landing page"
          />
          {/* <img
            className={styles.mainContainer_head_oimg}
            src="/assets/Landing/head_frame.svg"
            alt="landing page"
          /> */}
          <div className={styles.mainContainer_head_content}>
            <div className={styles.mainContainer_head_content_left}>
              <h1>
                Buy Sell and Connect <br />
                With Dubai’s most <br />
                Exclusive Real-Estate <br />
                Community <br />
              </h1>
              <div className={styles.mainContainer_head_content_left_buttons}>
                <div
                  className={styles.mainContainer_head_content_left_buttons_btn}
                >
                  <button
                    style={{ cursor: 'pointer' }}
                    onClick={handleButtonClick}
                  >
                    <Image src={googlePlayButton} alt="google-play-store" />
                    {/* <img
                      src="/assets/Landing/google-play-button.png"
                      alt="google-play-store"
                    /> */}
                  </button>
                  <button
                    style={{ cursor: 'pointer' }}
                    onClick={handleButtonClick}
                  >
                    <Image src={appStoreButton} alt="apple-app-store" />
                    {/* <img
                      src="/assets/Landing/app-store-button.png"
                      alt="apple-app-store"
                    /> */}
                  </button>
                </div>
                <div
                  className={
                    styles.mainContainer_head_content_left_buttons_search
                  }
                >
                  <button
                    style={{ cursor: 'pointer', width: '100%' }}
                    onClick={() => router.push('/register')}
                  >
                    Become a Member
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.mainContainer_head_content_right}>
              <Image
                className={styles.mainContainer_head_content_right_img}
                src={mobileHoldLanding}
                priority
                alt="landing page_hand"
              />
              {/* <img
                className={styles.mainContainer_head_content_right_img}
                src="/assets/Landing/mobile-hold-landing.png"
                alt="landing page_hand"
              /> */}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.mainContainer_network}>
        <Image src={agentsOval} alt="network" />
        {/* <img src="/assets/Landing/agents-oval.png" alt="network" /> */}
        <h2>
          Our <span>Network</span>, Your <span>Connectivity</span>, <br />{' '}
          Everyone’s <span>Victory</span>
        </h2>
      </div>
      <div className={styles.mainContainer_world}>
        <Image
          src={worldMap}
          alt="angle-world"
          className={styles.mainContainer_world_bimg}
        />
        {/* <img
          className={styles.mainContainer_world_bimg}
          src="/assets/Landing/world-map.svg"
          alt="world"
        /> */}
        <div className={styles.mainContainer_world_content}>
          <Image src={alntMobileDemo} alt="angle-mobile" />

          <div className={styles.mainContainer_world_content_texts}>
            <h2>
              <span>Distress Deals</span> you wont find anywhere else
            </h2>
            <span>
              Gain access to rare, below-market-value listings. Our exclusive
              distress deals offer the perfect opportunity to secure properties
              at unbeatable prices, giving your clients a significant advantage.
            </span>
            {/* <a href="">Learn More</a> */}
          </div>
        </div>
      </div>
      <div className={styles.mainContainer_curve}>
        <Image
          src={curveBg}
          priority
          alt="curve"
          className={styles.mainContainer_curve_bg}
        />
        {/* <img
          src="/assets/Landing/curve-bg.png"
          alt="curve"
          className={styles.mainContainer_curve_bg}
        /> */}
        <div className={styles.mainContainer_curve_texts}>
          <h2>
            <span>Important Documents</span> <br />
            Impress Clients
          </h2>
          <span>
            In the fast-paced world of luxury real estate, time is of the
            essence. With our cutting-edge platform, agents can instantly access
            vital property documents such as floor plans, payment plans, and
            ownership details. No more waiting—get everything...
          </span>
          {/* <a href="">Learn More</a> */}
        </div>
        <div className={styles.mainContainer_curve_mobile}>
          <Image src={mobileDownloads} alt="curve-mobil" />
          {/* <img src="/assets/Landing/mobile-downloads.png" alt="curve-mobile" /> */}
        </div>
      </div>
      <div className={styles.mainContainer_tailored}>
        <div className={styles.mainContainer_tailored_texts}>
          <h2>
            <span>Taylored Listings</span> on <br />
            Demand
          </h2>
          <span>
            Looking for something specific? Submit your buyer’s requirements and
            let the community of top agents help you find the perfect match.
            With access to an elite network, sourcing high-demand properties has
            never been easier.
          </span>
          {/* <a href="">Learn More</a> */}
        </div>
        <div className={styles.mainContainer_tailored_mobile}>
          <Image priority src={mobileTailored} alt="tailored-mobile" />
          {/* <img
            src="/assets/Landing/mobile-tailored.png"
            alt="tailored-mobile"
          /> */}
        </div>
      </div>
      <div className={styles.mainContainer_verified}>
        <div className={styles.mainContainer_verified_mobile}>
          <Image priority src={verifiedMobile} alt="verified-mobile" />

          {/* <img
            src="/assets/Landing/verified-mobile.png"
            alt="verified-mobile"
          /> */}
        </div>
        <div className={styles.mainContainer_verified_texts}>
          <h2>
            Verified <span>Up to Date</span> Database
          </h2>
          <span>
            Access a constantly updated, verified database of exclusive listings
            that you won’t find anywhere else. Our premium listings are curated
            for agents who deal in high-value, prestigious properties.
          </span>
          {/* <a href="">Learn More</a> */}
        </div>
      </div>
      <div className={styles.mainContainer_realtime}>
        <div className={styles.mainContainer_realtime_texts}>
          <h2>
            <span>Real Time</span> Market Insights
          </h2>
          <span>
            Stay ahead of the curve with live data on Dubai’s best-performing
            locations and projects. Track where the money is flowing in
            real-time and make informed decisions that elevate your business.
          </span>
          {/* <a href="">Learn More</a> */}
        </div>
        <div className={styles.mainContainer_realtime_mobile}>
          <Image priority src={realTimeMobile} alt="real-time-mobile" />

          {/* <img
            src="/assets/Landing/real-time-mobile.png"
            alt="real-time-mobile"
          /> */}
        </div>
      </div>
      <div className={styles.mainContainer_requirements}>
        <h2>
          <span>Exclusive</span> Listings
        </h2>
        <p>Take a look at exclusive listings added by agents daily!</p>
        <div style={{ width: isMobile ? '100%' : '1024px' }}>
          <AreaSpecialistRequirementLocation />
        </div>
      </div>
      <div className={styles.mainContainer_newsletter}>
        <div className={styles.mainContainer_newsletter_texts}>
          <h2>Join The Elite Network Today</h2>
          <span>
            As an agent in Dubai's fast-paced luxury market, you need the best
            tools to stay competitive. With Broker Terminal, you'll have access
            to exclusive properties, trusted agent collaborations, and premium
            market insights. If you're ready to elevate your business, apply for
            access today.
          </span>
          <div
            className={styles.mainContainer_head_content_left_buttons_search}
          >
            <input
              value={email}
              onChange={(e) => setemail(e.target.value)}
              type="text"
              placeholder="Email Address"
            />
            <button onClick={handleSubscribe}>Subscribe Now</button>
          </div>
        </div>
        <div className={styles.mainContainer_newsletter_mobile}>
          <Image src={joinElite} alt="real-time-mobile" />

          {/* <img src="/assets/Landing/join-elite.png" alt="real-time-mobile" /> */}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
