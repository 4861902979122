import React from 'react';

interface IconProps {
  color?: string;
  width?: number;
  height?: number;
}

const MobileHomeIcon: React.FC<IconProps> = ({
  color = '#ffffff',
  width = 26,
  height = 26,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.25 9.7474L13 2.16406L22.75 9.7474V21.6641C22.75 22.2387 22.5217 22.7898 22.1154 23.1961C21.7091 23.6025 21.158 23.8307 20.5833 23.8307H5.41667C4.84203 23.8307 4.29093 23.6025 3.8846 23.1961C3.47827 22.7898 3.25 22.2387 3.25 21.6641V9.7474Z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 23.8333V13H16.25V23.8333"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MobileHomeIcon;
