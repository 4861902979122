import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import SortIcon from '@mui/icons-material/Sort';

interface MCustomSortProps {
  sortBy: string;
  setSortBy: (arg: string) => void;
  screen: string;
}
const sortOptions = [
  {
    value: 'latest',
    label: 'Latest',
  },
  {
    value: 'oldest',
    label: 'Oldest',
  },
  {
    value: 'lth',
    label: 'Price (Low to High)',
  },
  {
    value: 'htl',
    label: 'Price (High to Low)',
  },
  {
    value: 'exclusive',
    label: 'Exclusive',
  },
];

const MCustomSort: React.FC<MCustomSortProps> = ({
  sortBy,
  setSortBy,
  screen,
}) => {
  const [title, setTitle] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (sortBy === 'latest') {
      setTitle(
        `Latest ${
          screen === 'seller'
            ? 'Listings'
            : screen === 'distress'
            ? 'Distress'
            : 'Requirements'
        } in Terminal`
      );
    } else if (sortBy === 'oldest') {
      setTitle(
        `Oldest ${
          screen === 'seller'
            ? 'Listings'
            : screen === 'distress'
            ? 'Distress'
            : 'Requirements'
        } in Terminal`
      );
    } else if (sortBy === 'htl') {
      setTitle(
        `Highest to Lowest Price ${
          screen === 'seller'
            ? 'Listings'
            : screen === 'distress'
            ? 'Distress'
            : 'Requirements'
        } in Terminal`
      );
    } else if (sortBy === 'lth') {
      setTitle(
        `Lowest to Highest Price ${
          screen === 'seller'
            ? 'Listings'
            : screen === 'distress'
            ? 'Distress'
            : 'Requirements'
        } in Terminal`
      );
    }

    return () => {
      setTitle('Latest Listings in Terminal');
    };
  }, [sortBy, screen]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px',
        paddingLeft: '8px',
      }}
    >
      <div>
        <h2
          style={{
            color: '#f2f2f2',
            fontSize: '14px',
            fontWeight: 500,
          }}
        >
          {title}
        </h2>
        {screen === 'distress' && (
          <h4
            style={{
              color: '#969696',
              fontSize: '12px',
              fontWeight: 500,
            }}
          >
            (Avg price/sqft from last 5 transactions){' '}
          </h4>
        )}
      </div>
      <IconButton onClick={openMenu} color="inherit">
        <SortIcon
          sx={{
            color: '#f2f2f2',
          }}
        />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        PaperProps={{
          style: {
            backgroundColor: '#2c2c2c',
          },
        }}
      >
        {sortOptions.map((option, index) => (
          <MenuItem
            key={option.value + index}
            onClick={() => {
              setSortBy(option.value);
              closeMenu();
            }}
            sx={{
              color: '#f2f2f2',
              fontSize: 12,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '30px',
            }}
          >
            {option.label}
            {sortBy === option.value && <CheckIcon sx={{ color: '#d12d34' }} />}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MCustomSort;
