import DetectMobile from '@/utils/DetectMobile';

const SlideRightSvg = ({
  color = '#F0F1F4',
  width = DetectMobile() ? 32 : 40,
  height = DetectMobile() ? 32 : 40,
  disabled = false,
  color2 = '#363352',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="type=right, state=Default" opacity={disabled ? '0.6' : '1'}>
        <rect width="40" height="40" rx="20" fill={color} />
        <g id="icon/arrow">
          <path
            id="Vector"
            d="M18 15L21.6745 19.1162C22.1085 19.6023 22.1085 20.3977 21.6745 20.8838L18 25"
            stroke={color2}
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default SlideRightSvg;
