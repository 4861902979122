import api from '@/services/api';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { useCallback, useState } from 'react';

export interface ListingDataProps {
  _id: string;
  project: string;
  type: string;
  shorturl: string;
  bed: string;
  video: string;
  brochure: string;
  locationImage: string[];
  price: number;
  featureNumber: number;
  images: Array<string>;
  user: {
    _id: string;
    profilePic: string;
    lastName: string;
    firstName: string;
    phoneNumber: string;
    RealEstateName: string;
  };
  lastsold: any;
  area: number;
  notes: string;
  title: string;
  listingType: string;
  labels: string;
  location: string;
  personalNote: string;
  contractTerm: string;
  status: string;
  createdAt: Date;
  avgAEDSqf: { avgAEDSqf: number };
  AEDSqf: number;
}

interface UserListingViewModelProps {
  data: any;
  isFetching: boolean;
  handleRefresh: () => void;
  setScreen: (any: string) => void;
  error: any;
  isFetchingNextPage: boolean;
  fetchNextPage: (...args: any) => void;
  hasNextPage: boolean;
  sortBy: string;
  setSortBy: (arg: string) => void;
  screen: string;
}

const UserListingViewModel = (selected: string): UserListingViewModelProps => {
  const [screen, setScreen] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('latest');

  const limit = 10;
  const {
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    error,
    refetch,
    isFetching,
  }: UseInfiniteQueryResult = useInfiniteQuery({
    queryKey: ['userListings', selected, screen, sortBy],
    queryFn: ({ pageParam = 1 }) =>
      api
        .get(
          `/agent/agent-listings?type=${selected}&listingType=${screen}&page=${pageParam}&limit=${limit}&sort=${sortBy}`
        )
        .then((res) => res.data),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.listings.length < limit ? undefined : allPages.length + 1;
    },

    initialPageParam: 1,
    refetchOnWindowFocus: false,
  });

  const handleRefresh = useCallback(() => {
    refetch();
  }, [refetch]);

  return {
    data,
    isFetching,
    handleRefresh,
    setScreen,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    sortBy,
    setSortBy,
    screen,
  };
};

export default UserListingViewModel;
