import * as React from 'react';
import { isMobile } from 'react-device-detect';

const DetectMobile = () => {
  // const [_isMobile, setMobile] = React.useState(false);

  // React.useEffect(() => {
  //   setMobile(isMobile);
  // }, [setMobile]);
  // return _isMobile;

  const [_isMobile, setMobile] = React.useState(false);

  React.useEffect(() => {
    setMobile(isMobile);
  }, []);

  const memoizedIsMobile = React.useMemo(() => _isMobile, [_isMobile]);

  return memoizedIsMobile;
};

export default DetectMobile;
